import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/dashboard',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perfil',
      name: 'perfil',
      component: () => import('@/views/perfil/AccountSetting.vue'),
      meta: {
        pageTitle: 'Perfil',
        breadcrumb: [
          {
            text: 'Perfil',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pre-alertas',
      name: 'pre-alertas',
      component: () => import('@/views/prealertas/prealertas.vue'),
      meta: {
        pageTitle: 'Pre Alertas',
        breadcrumb: [
          {
            text: 'Pre-Alertas',
            active: true,
          },
        ],
      }
    },
    {
      path: '/pre-alertas/create',
      name: 'crear-pre-alertas',
      component: () => import('@/views/prealertas/create.vue'),
      meta: {
        pageTitle: 'Crear Pre Alertas',
        breadcrumb: [
          {
            text: 'Pre-Alertas',
            active: true,
          },
        ],
      }
    },
    {
      path: '/destinatarios',
      name: 'destinatarios',
      component: () => import('@/views/destinatarios/destinatarios.vue'),
      meta: {
        pageTitle: 'Destinatarios',
        breadcrumb: [
          {
            text: 'Destinatarios',
            active: true,
          },
        ],
      }
    },
    {
      path: '/destinatarios/create',
      name: 'crear-destinatarios',
      component: () => import('@/views/destinatarios/Create.vue'),
      meta: {
        pageTitle: 'Crear destinatarios',
        breadcrumb: [
          {
            text: 'Destinatarios',
            active: true,
          },
        ],
      }
    },
    {
      path: '/historial',
      name: 'historial',
      component: () => import('@/views/historial/historial.vue'),
      meta: {
        pageTitle: 'Historial',
        breadcrumb: [
          {
            text: 'Historial',
            active: true,
          },
        ],
      }
    },
    {
      path: '/despachos',
      name: 'despachos',
      component: () => import('@/views/despachos/despachos.vue'),
      meta: {
        pageTitle: 'Despachos',
        breadcrumb: [
          {
            text: 'Despachos',
            active: true,
          },
        ],
      }
    },
    {
      path: '/preliquidaciones-adjuntar/:solicitudId',
      name: 'preliquidaciones-adjuntar',
      component: () => import('@/views/despachos/adjuntar.vue'),
      meta: {
        pageTitle: 'Despachos',
        breadcrumb: [
          {
            text: 'Despachos',
            active: true,
          },
        ],
      }
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/check-status',
      name: 'check-status',
      component: () => import('@/views/authentication/CheckStatus'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
export default router
