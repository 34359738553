import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://fenixcargo.helgasys.com/',
  headers:  {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
})

Vue.prototype.$http = axiosIns
export default axiosIns
