import store from "@/store";
export default {
    namespaced: true,
    state: {
        infoUser: []
    },
    mutations: {
    },
    actions: {
        updInfoUserAction( context, userData ){
           context.state.infoUser = userData
        }
    },
    getters: {
        getDataUser: state => {
            return state.infoUser
        },
        getFullName: state => {
            let fullName = ""
            fullName = state.infoUser.primer_nombre + ' '
            if( state.infoUser.segundo_nombre != null ){
                fullName += state.infoUser.segundo_nombre + ' '
            }
            if(state.infoUser.primer_apellido != null){
                fullName += state.infoUser.primer_apellido + ' '
            }
            if( state.infoUser.segundo_apellido != null){
                fullName += state.infoUser.segundo_apellido + ' '
            }
            return fullName
        },
        getCasillero: state =>{
            return state.infoUser.codigo_casillero
        },
        getIduser: state =>{
            return state.infoUser.id
        }
    },
}
